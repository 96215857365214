import { Color } from '../../../40-utils/color/Color';
import { makeSkinModule } from '../../../50-systems/StyleSystem/makers/makeSkinModule';

export const LightDevelopmentSkinModule = makeSkinModule({
    manifest: { name: 'LightDevelopmentSkin' },
    skin: {
        // TODO: Some smart way how to inherit style from DefaultEducationSkin

        welcomeWallpaper: {
            // TODO: Put this 👇 file into the assets folder of the module
            backgroundImage: `url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/wallpapers/grid-light-wallpaper.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },

        borderRadius: 6,
        colors: {
            primary: Color.fromHex('#009edd'),
            light: Color.fromHex('#f2f2f2'),
            dark: Color.fromHex('#4e4e4e'),

            success: Color.fromHex('#3da948'),
            warning: Color.fromHex('#ffcc11'),
            error: Color.fromHex('#cd1719'),
            danger: Color.fromHex('#cd1719'),

            white: Color.fromString('white'),
            black: Color.fromString('black'),

            overlay: Color.fromString('black').withAlpha(0.7),
        },
        darken: -0.05,
        lighten: 0.05,
        circles: true,
    },
});
