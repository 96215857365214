import { Registration } from 'destroyable';
import React from 'react';
import { BehaviorSubject } from 'rxjs';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../../50-systems/ToolbarSystem/0-ToolbarSystem';
import { StopwatchArt } from './StopwatchArtModule';
import { TimerArt } from './TimerArtModule';
import { TimerToolMenu } from './TimerToolMenu';
import { ToolTimerType } from './ToolTimerType';

internalModules.declareModule(() => {
    const toolTimerType = new BehaviorSubject(ToolTimerType.Stopwatch);

    return makeIconModuleOnModule({
        manifest: {
            name: 'TimerTool',
            title: { en: 'Stopwatch and timer', cs: 'Stopky a časovač' },
            description: {
                en: 'Measure time and create simple countdowns',
                cs: 'Měřte čas, nebo vytvářejte jednoduché odpočty',
            },

            categories: ['Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/icons/timer.svg',
            author: Authors.rosecky,
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'timer',
            order: 61,
            icon: 'timer',
            boardCursor: 'default',
            menu() {
                return <TimerToolMenu {...{ toolTimerType }} />;
            },
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, materialArtVersioningSystem, toolbarSystem } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'materialArtVersioningSystem',
                        'toolbarSystem',
                    );
                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        const boardPosition = collSpace.pickPoint(touch.firstFrame.position).point;

                        let newArt;
                        switch (toolTimerType.value) {
                            case ToolTimerType.Timer:
                                newArt = new TimerArt(
                                    boardPosition,
                                    60 * 1000, // a minute
                                );
                                break;
                            case ToolTimerType.Stopwatch:
                                newArt = new StopwatchArt(boardPosition);
                                break;
                        }

                        const operation = materialArtVersioningSystem.createPrimaryOperation();
                        operation.newArts(newArt);
                        operation.persist();

                        toolbarSystem.getToolbar(ToolbarName.Tools).clickOnIcon('selection');
                    }),
                );
            },
        },
    });
});

/**
 * Note: In future this file will we in independent repository as external module.
 */
