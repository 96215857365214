import * as screenfull from 'screenfull' /* TODO: Probably in future create custom system */;
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeIconModule } from '../../../50-systems/ModuleStore/makers/makeIconModule';
import { ToolbarName } from '../../../50-systems/ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModule({
        manifest: {
            name: '@collboard/internal/fullscreen',
            deprecatedNames: ['@collboard/fullscreen', 'Fullscreen'],
            title: { en: 'Toggle board fullscreen mode', cs: 'Zobrazit tabuli na celou obrazovku' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/icons/fullscreen-on.svg',

            author: Authors.collboard,
            requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Navigation,
        icon: {
            order: 15,
            section: 1,
            icon: 'fullscreen-on',
            onClick: () => {
                if (screenfull.isEnabled) {
                    screenfull.toggle();
                }
            },
            inactive: !screenfull.isEnabled,
        },
    }),
);
