import React from 'react';
import { classNames } from '../40-utils/classNames';
import { factor, Factorable } from '../40-utils/IFactory';
import { ISystems } from '../50-systems/00-SystemsContainer/ISystems';
import { consolexBase } from '../consolex';
import { Abstract2dArt } from './26-Abstract2dArt';

/*
 * Note: [🖇] This is not in the internal module because some of the Arts are so
 *       tightly connected with the core that they need to be there, not in
 *       optionally deactivateable module.
 *
 */

/**
 * Art which renders loading of something
 * It is usefull primarly with virtual commits:
 *  >
 *  > virtualArtVersioningSystem
    >    .createPrimaryOperation()
    >    .newArts(
    >      new LoadingArt('aaa').setShift((collSpace.pickPoint(touch.firstFrame.position)).point),
    >    )
    >    .persist();
 *
 * @collboard-modules-sdk
 */
export class LoadingArt extends Abstract2dArt {
    public static serializeName = 'Loading';
    public static manifest = {
        // Note+TODO: All modules should be in format @collboard/internal/module-name but we started with art modules
        name: '@collboard/internal/loading-art',
        deprecatedNames: '@collboard/loading-art',
    };

    /**
     * @param alt What is loading
     */
    public constructor(public alt: Factorable<string /* <- TODO: [🌔] Make this better */>) {
        super();
    }

    public get topLeft() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }
    public get bottomRight() /* TODO: This should be done by LIB xyzt boundingBox  */ {
        return this.shift;
    }

    public get acceptedAttributes() {
        return [];
    }

    public async render(isSelected: boolean, systems: ISystems) {
        const { appState } = await systems.request('appState');
        const size = 100 / appState.transform.value.scale.x;

        // TODO: Somehow use alt text
        return (
            <div
                // TODO: [🍒][0]! This should became <ArtOwnShell
                className={classNames('art', isSelected && 'selected')}
                onClick={() =>
                    consolexBase
                        .group(`🔄 Loading of "${factor(this.alt) /* <- TODO: [🌔] Make this better */}"`)
                        .trace()
                        .end()
                }
                style={{
                    position: 'absolute',
                    left: this.shift.x! - size / 2 /* <- LIB xyzt .toTopLeft() */,
                    top: this.shift.y! - size / 2 /* <- LIB xyzt .toTopLeft() */,
                    width: size,
                    height: size,
                    backgroundColor: '#555',
                    borderRadius: '100%',

                    // TODO: DRY
                    backgroundImage: `url(https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/loading@2x.png)`,
                    backgroundSize: `contain`,
                    backgroundRepeat: `no-repeat`,
                    backgroundPosition: `center center`,
                    animationName: `spinning`,
                    animationDuration: `4s`,
                    animationIterationCount: `infinite`,
                }}
            ></div>
        );
    }
}

/**
 * TODO: @roseckyj maybe look at this and fix design flaws
 * TODO: [🍎]  Use IMaterial instead of color
 * TODO: [🍎]  Use IShape instead of weight, points,...
 * TODO: [🎚️] Implement IArt
 *
 */
