import { Registration } from 'destroyable';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { createSelectionToolBehavior } from './behaviors/selectionTool/0-createSelectionToolBehavior';
internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/selection-tool',
            deprecatedNames: ['@collboard/selection-tool', 'SelectionTool'],
            title: { en: 'Selection', cs: 'Výběr' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.94/icons/cursor.svg',

            author: Authors.collboard,
            // TODO: [🏰]> requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Tools,
        icon: {
            name: 'selection',

            icon: 'cursor',
            boardCursor: 'default',
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController } = await systems.request('touchController');

                const selectionToolBehavior = await createSelectionToolBehavior();

                return Registration.fromSubscription(async (registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        await selectionToolBehavior({ registerAdditionalSubscription, touch, systems });
                    }),
                );
            },
        },
    }),
);

/**
 * TODO: [🏰] We want to be able selecting, copying, exporting, etc. arts in view mode
 *       Now it is messed by the selection which is always behaving like with edit capabilities
 */
